body {
  margin:auto;
  align-items: "center";
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cmhHeader {
  color: white;
	background : #192B39;
	background : rgba(25, 43, 57, 1);
  background-image: url("mhvotes2021.svg");
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  padding:2em;
}
.app-container {
  max-width:800px;
}
	.teal_header
{
	background : #95D6DB;
	background : rgba(149, 214, 219, 1);
}
.NormalCharacterStyle
{
	font-family : Usual;
	font-size : 13px;
	line-height : 19px;
	color : #95D6DB;
	color : rgb(149, 214, 219); 
	text-decoration : underline;
}
.grey_header
{
	background : #F3F3F4;
	background : rgba(243, 243, 244, 1);
  padding: 1em;
}
.NormalCharacterStyle1
{
	font-family : Usual;
	font-weight : bold;
	font-size : 12px;
	color : #097DBD;
	color : rgb(9, 125, 189);
}
.city_logo
{
	background-image : url(city_logo.png);
	background-repeat : no-repeat;
}
.navy_footer
{
	background : #192B39;
	background : rgba(25, 43, 57, 1);
}
.NormalCharacterStyle2
{
	font-family : Usual;
	font-size : 13px;
	line-height : 19px;
	color : #000000;
	color : rgb(0, 0, 0);
}
.mh_votes_logo
{
	background-image : url(mh_votes_logo.png);
	background-repeat : no-repeat;
}
.NormalCharacterStyle3
{
	font-family : Usual;
	font-weight : bold;
	font-size : 24px;
	color : #000000;
	color : rgb(0, 0, 0);
}
